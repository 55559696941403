.loader-overlay,
.loader-overlay-color {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    border-radius: 10px
}

.loader-overlay-color {
    background-color: hsla(0, 0%, 100%, .75);
}

.loader-container {
    position: absolute;
    width: 150px;
    bottom: 0;
    height: 150px;
    padding: 30px;
    text-align: center;
    left: 0;
    margin: auto;
    right: 0;
    top: 0
}

.loader-line {
    height: 49px;
    left: 25px;
    top: 25px;
    overflow: hidden;
    animation: cssload-spin 2.3s cubic-bezier(.175, .885, .32, 1.275) infinite;
    transform-origin: 50% 100%
}

.loader-line,
.loader-line-wrap {
    box-sizing: border-box;
    position: absolute;
    width: 97px
}

.loader-line-wrap {
    border: 4px solid transparent;
    border-radius: 100%;
    height: 97px;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 0
}

.loader-line:first-child {
    animation-delay: -57.5ms
}

.loader-line:nth-child(2) {
    animation-delay: -115ms
}

.loader-line:nth-child(3) {
    animation-delay: -.1725s
}

.loader-line:nth-child(4) {
    animation-delay: -.23s
}

.loader-line:nth-child(5) {
    animation-delay: -.2875s
}

.loader-line:first-child .loader-line-wrap {
    border-color: #9c3434;
    height: 88px;
    width: 88px;
    top: 7px
}

.loader-line:nth-child(2) .loader-line-wrap {
    border-color: #9c3434;
    height: 74px;
    width: 74px;
    top: 14px
}

.loader-line:nth-child(3) .loader-line-wrap {
    border-color: #9c3434;
    height: 60px;
    width: 60px;
    top: 20px
}

.loader-line:nth-child(4) .loader-line-wrap {
    border-color: #9c3434;
    height: 47px;
    width: 47px;
    top: 27px
}

.loader-line:nth-child(5) .loader-line-wrap {
    border-color: #9c3434;
    height: 33px;
    width: 33px;
    top: 34px
}

@keyframes cssload-spin {

    0%,
    15% {
        transform: rotate(0)
    }

    to {
        transform: rotate(1turn)
    }
}