.tooltip-feedback {
    background-color: #cee6ed !important;
    color: #485384 !important;
    //font-weight: 700 !important;
    //font-size: 11px !important;
    // font-size: 1rem!important;
    font-weight: 400!important;
    border-radius: 2px !important;
    line-height: 1.5;
    text-align: center;
  }
.tooltip-feedback.tooltip-shadow {
    box-shadow: 0 0px 0px 0px !important;
}
.tooltip-feedback .tooltip-arrow:after {
    background-color: #cee6ed !important;
}
input,
textarea {
    display: block;
    width: 100%;
    font-family: Lato, sans-serif;
    font-weight: 400;
    line-height: 1;
    border-radius: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 15px;
    font-size: 1rem;
    color: black;
    background: #fff;
    border: 1px solid #a6a6a6
}

/* input:hover,
textarea:hover {
    color: black;
    background: #fafafa;
    border: 1px solid #8d8d8d
}

input:focus,
textarea:focus {
    color: #a6a6a6;
    background: #fafafa;
    border: 1px solid #0a396d
}

input:disabled,
textarea:disabled {
    cursor: no-drop;
    color: #a6a6a6;
    background: #a6a6a6;
    border: 1px solid #8d8d8d
} */

.select select.error,
input.error,
textarea.error {
    color: #db0000;
    border-color: #db0000
}

.select select.warning,
input.warning,
textarea.warning {
    color: #ff8831;
    border-color: #ff8831
}

.select select.success,
input.success,
textarea.success {
    color: #4caf50;
    border-color: #4caf50
}

.select select.info,
input.info,
textarea.info {
    color: #5bc0de;
    border-color: #5bc0de
}

textarea {
    max-width: 100%;
    -webkit-transition:"color ${transition-fast} ease-in-out, background ${transition-fast} ease-in-out, border ${transition-fast} ease-in-out";
    transition:"color ${transition-fast} ease-in-out, background ${transition-fast} ease-in-out, border ${transition-fast} ease-in-out"
}

/* input[type=search] {
    border-radius: 0;
    -webkit-appearance: none
}

input[type=file],
input[type=search] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

input[type=file] {
    padding: 0;
    height: auto;
    background: transparent;
    border: none
} */

form .form-group {
    margin: 0 -.5rem 1.5rem;
    padding: 0 .5rem
}

/* form label[for] {
    // display: block;
    margin: 10px 0 .5rem;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    // color: #a6a6a6
}
form .form-check-label {
    color: #b92234;
} */

.form-captcha-container {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.form-recaptcha.is-invalid {
    border-color: #dc3545;
    margin-bottom: 10px;
    // border: 1px solid #dc3545;
}
.invalid-recaptcha {
    width: 100%;
    font-size: 80%;
    display: block;
}


.form-message {
    margin: 5px 0 0;
    font-size: .875rem;
    color: #a6a6a6
}

.form-message.msg-error {
    color: #db0000
}

.form-message.msg-warning {
    color: #ff8831
}

.form-message.msg-success {
    color: #4caf50
}

.form-message.msg-info {
    color: #5bc0de
}

/* [type=radio]:checked,
[type=radio]:not(:checked) {
    position: absolute;
    left: -9999px;
    visibility: hidden
}

[type=radio]:checked+label,
[type=radio]:not(:checked)+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 1rem;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

[type=radio]+label:after,
[type=radio]+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    z-index: 0;
    border-radius: 50%;
    -webkit-transition: .3s ease;
    transition: .3s ease
}

[type=radio]:not(:checked)+label:before {
    border: 2px solid #a6a6a6
}

[type=radio]:not(:checked)+label:after {
    border: 2px solid #a6a6a6;
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0)
}

[type=radio]:checked+label:before {
    border: 2px solid #0a396d
}

[type=radio]:checked+label:after {
    border: 2px solid #0a396d;
    background-color: #0a396d;
    z-index: 0;
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5)
}

[type=radio].with-gap:checked+label:before {
    border: 2px solid #0a396d
}

[type=radio].with-gap:checked+label:after {
    border: 2px solid #0a396d;
    background-color: #0a396d;
    z-index: 0;
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5)
}

[type=radio].with-gap:disabled:checked+label:before {
    border: 2px solid #a6a6a6
}

[type=radio].with-gap:disabled:checked+label:after {
    border: none;
    background-color: #a6a6a6
}

[type=radio]:disabled:checked+label:before,
[type=radio]:disabled:not(:checked)+label:before {
    border: 2px solid #a6a6a6
}

[type=radio]:disabled+label {
    color: #a6a6a6
}

[type=radio]:disabled:not(:checked)+label:before {
    border-color: #a6a6a6
}

[type=radio]:disabled:checked+label:after {
    border: none;
    background-color: #a6a6a6
} */




/* [type=checkbox]:checked,
[type=checkbox]:not(:checked) {
    position: absolute;
    left: -9999px;
    visibility: hidden
}

[type=checkbox]+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

[type=checkbox]+label:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    z-index: 0;
    border: 2px solid #a6a6a6;
    border-radius: 1px;
    margin-top: 0;
    -webkit-transition: .2s;
    transition: .2s
}

[type=checkbox]:not(:checked):disabled+label:before {
    border: none;
    background-color: #a6a6a6
}

[type=checkbox]:disabled+label {
    color: #a6a6a6
}

[type=checkbox]:checked+label:before {
    top: -4px;
    left: -3px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #0a396d;
    border-bottom: 2px solid #0a396d;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

[type=checkbox]:checked:disabled+label:before {
    border-right: 2px solid #a6a6a6;
    border-bottom: 2px solid #a6a6a6
}

[type=checkbox]:indeterminate+label:before {
    left: -10px;
    top: -11px;
    width: 10px;
    height: 22px;
    border-top: none;
    border-left: none;
    border-right: 2px solid #0a396d;
    border-bottom: none;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

[type=checkbox]:indeterminate:disabled+label:before {
    border-right: 2px solid #a6a6a6;
    background-color: transparent
}

[type=checkbox].filled-in+label:after {
    border-radius: 2px
}

[type=checkbox].filled-in+label:after,
[type=checkbox].filled-in+label:before {
    content: "";
    left: 0;
    position: absolute;
    transition: border .25s, background-color .25s, width .2s .1s, height .2s .1s, top .2s .1s, left .2s .1s;
    z-index: 1
}

[type=checkbox].filled-in:not(:checked)+label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%
}

[type=checkbox].filled-in:not(:checked)+label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #a6a6a6;
    top: 0;
    z-index: 0
}

[type=checkbox].filled-in:checked+label:before {
    top: 2px;
    left: 1px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

[type=checkbox].filled-in:checked+label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #0a396d;
    background-color: #0a396d;
    z-index: 0
}

[type=checkbox].filled-in:disabled:not(:checked)+label:before {
    background-color: transparent;
    border: 2px solid transparent
}

[type=checkbox].filled-in:disabled:not(:checked)+label:after {
    border-color: transparent;
    background-color: #e8e8e8
}

[type=checkbox].filled-in:disabled:checked+label:before {
    background-color: transparent;
    border-right-color: #a6a6a6;
    border-bottom-color: #a6a6a6
}

[type=checkbox].filled-in:disabled:checked+label:after {
    background-color: #e8e8e8;
    border-color: #e8e8e8
}

[type=checkbox].filled-in-danger+label:after {
    border-radius: 2px
}

[type=checkbox].filled-in-danger+label:after,
[type=checkbox].filled-in-danger+label:before {
    content: "";
    left: 0;
    position: absolute;
    transition: border .25s, background-color .25s, width .2s .1s, height .2s .1s, top .2s .1s, left .2s .1s;
    z-index: 1
}

[type=checkbox].filled-in-danger:not(:checked)+label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%
}

[type=checkbox].filled-in-danger:not(:checked)+label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #a6a6a6;
    top: 0;
    z-index: 0
}

[type=checkbox].filled-in-danger:checked+label:before {
    top: 0;
    left: 1px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

[type=checkbox].filled-in-danger:checked+label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    background-color: #db0000;
    z-index: 0
} */

select {
    display: block;
    width: 100%
}

.select {
    position: relative;
    display: block
}

.select select {
    padding: 10px 15px;
    line-height: auto;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #a6a6a6;
    cursor: pointer;
    outline: 0;
    background: #fff;
    border: 1px solid #a6a6a6;
    border-radius: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.select select::-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000
}

.select select::-ms-expand {
    display: none
}

.select select:hover {
    color: #a6a6a6;
    background: #fafafa;
    border: 1px solid #8d8d8d
}

.select select:focus {
    color: #a6a6a6;
    background: #fafafa;
    border: 1px solid #0a396d
}

.select:after {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -.2rem;
    width: 0;
    height: 0;
    content: "";
    pointer-events: none;
    border-left: .4rem solid transparent;
    border-right: .4rem solid transparent;
    border-top: .4rem solid #455a64
}
@media screen and (min-width:0) {
    .select select {
        padding: .5em
    }

    .select:after,
    .select:before {
        display: none
    }
}

@-moz-document url-prefix() {
    .select select {
        text-indent: .01px;
        text-overflow: "";
        padding-right: 1rem
    }

    .select:before {
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        pointer-events: none;
        width: 1em;
        border: 1px solid #a6a6a6;
        border-width: 1px 1px 1px 0;
        background: #fff;
        border-radius: 0 0 0 0;
        background-clip: padding-box
    }

    .select.large:before {
        width: 1.5em
    }
}