body {
  line-height: 1;  
}
a, abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
:root {
  font-family: Lato,sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
}
*, :after, :before {
  box-sizing: border-box;
}
img.img-responsive {
  width: 100%;
  height: auto
}
ol, ul {
  list-style: none;
}
.container {
    padding-left: 0;
    padding-right: 0;
  @media (min-width: 48rem) {
    max-width: 44rem !important;
  }
  @media (min-width: 64rem) {
    max-width: 60rem !important;
  }
  @media (min-width: 75rem) {
    max-width: 71rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @media (min-width: 87.5rem) {
    max-width: 72.2rem !important;
  }  
}

.hidden-sm-down {  
  @media (max-width: 63.9375rem) {
    display: none !important;
    visibility: hidden;
    opacity: 0;
  }
}
.visible-sm-down {
  display: none;
  visibility: hidden;
  opacity: 0;
  @media (max-width: 63.9375rem) {
    display: block;
    visibility: visible;
    opacity: 1;
  }
}
h1 {
  font-weight: 700;
}
h1, h2 {
  line-height: 1;
}
.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row {
  @media (max-width: 47.9375rem) {  
    margin: 0;
  }
}
