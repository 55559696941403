//Colors
$primary:#0a396d;
$secondary: #b92234;
$rojo: #a62733;
$blue-1: #163a70;
$blue-2: #5bc0de;

$font-family-sans-serif: Lato, sans-serif;
$btn-border-radius: 0;
$btn-font-weight: 500;
$material-icons-codepoints: () !default; /* Sass map of icon names and codepoints */
$material-icons-font-path: '' !default;
$material-icons-font-name: 'MaterialIcons-Regular' !default;
$material-icons-font-size: 24px !default;
$material-icons-font-family: 'Material Icons' !default;
