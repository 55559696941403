.body{
  font-family: 'Lato', sans-serif;
  background:#F2F2F2;
  color: #153e69;
}

/*Fonts*/

@font-face {
  font-family: 'ClanOT';
  src: url('../fonts/ClanOT/ClanOT-Bold.eot');
  src: url('../fonts/ClanOT/ClanOT-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-Bold.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-Bold.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-Bold.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-Bold.svg#ClanOT-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ClanOT';
  src: url('../fonts/ClanOT/ClanOT-BoldItalic.eot');
  src: url('../fonts/ClanOT/ClanOT-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-BoldItalic.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-BoldItalic.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-BoldItalic.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-BoldItalic.svg#ClanOT-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ClanOT-BookItalic';
  src: url('../fonts/ClanOT/ClanOT-BookItalic.eot');
  src: url('../fonts/ClanOT/ClanOT-BookItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-BookItalic.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-BookItalic.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-BookItalic.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-BookItalic.svg#ClanOT-BookItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'ClanOT-Book';
  src: url('../fonts/ClanOT/ClanOT-Book.eot');
  src: url('../fonts/ClanOT/ClanOT-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-Book.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-Book.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-Book.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-Book.svg#ClanOT-Book') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ClanOT-News';
  src: url('../fonts/ClanOT/ClanOT-News.eot');
  src: url('../fonts/ClanOT/ClanOT-News.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-News.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-News.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-News.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-News.svg#ClanOT-News') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ClanOT-NewsItalic';
  src: url('../fonts/ClanOT/ClanOT-NewsItalic.eot');
  src: url('../fonts/ClanOT/ClanOT-NewsItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-NewsItalic.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-NewsItalic.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-NewsItalic.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-NewsItalic.svg#ClanOT-NewsItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ClanOT';
  src: url('../fonts/ClanOT/ClanOT-Medium.eot');
  src: url('../fonts/ClanOT/ClanOT-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-Medium.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-Medium.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-Medium.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-Medium.svg#ClanOT-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ClanOT';
  src: url('../fonts/ClanOT/ClanOT-BlackItalic.eot');
  src: url('../fonts/ClanOT/ClanOT-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-BlackItalic.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-BlackItalic.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-BlackItalic.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-BlackItalic.svg#ClanOT-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'ClanOT';
  src: url('../fonts/ClanOT/ClanOT-Black.eot');
  src: url('../fonts/ClanOT/ClanOT-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-Black.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-Black.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-Black.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-Black.svg#ClanOT-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ClanOT-Ultra';
  src: url('../fonts/ClanOT/ClanOT-Ultra.eot');
  src: url('../fonts/ClanOT/ClanOT-Ultra.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-Ultra.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-Ultra.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-Ultra.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-Ultra.svg#ClanOT-Ultra') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ClanOT';
  src: url('../fonts/ClanOT/ClanOT-MediumItalic.eot');
  src: url('../fonts/ClanOT/ClanOT-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ClanOT/ClanOT-MediumItalic.woff2') format('woff2'),
  url('../fonts/ClanOT/ClanOT-MediumItalic.woff') format('woff'),
  url('../fonts/ClanOT/ClanOT-MediumItalic.ttf') format('truetype'),
  url('../fonts/ClanOT/ClanOT-MediumItalic.svg#ClanOT-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

/* @font-face {
  font-family: "jetsmart-icons";
  src:url("../fonts/js-icons/jetsmart-icons.eot");
  src:url("../fonts/js-icons/jetsmart-icons.eot?#iefix") format("embedded-opentype"),
  url("../fonts/js-icons/jetsmart-icons.woff") format("woff"),
  url("../fonts/js-icons/jetsmart-icons.ttf") format("truetype"),
  url("../fonts/js-icons/jetsmart-icons.svg#jetsmart-icons") format("svg");
  font-weight: normal;
  font-style: normal;

} */
/*Colors*/

.bg-blue-js{
  background-color: #00396f;
}

.bg-red-js{
  background-color: #b72831;
}

.bg-light-blue-js{
  background-color: #00abc8;
}

.bg-grey-js{
  background-color: #f2f2f2;
}

.bg-yellow-js{
  background-color: #f5a41a;
}

.color-blue-js{
  color: #00396f;
}

.color-red-js{
  color: #b72831!important;
}

.color-light-blue-js{
  color: #00abc8!important;
}

.color-grey-js{
  color: #f2f2f2;
}

.color-yellow-js{
  color: #f5a41a;
}

.color-white-js{
  color: #FFFFFF;
}

.font-lato-regular{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.font-lato-bold{
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #153e69;
}
.font-lato-black{
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}

/* Custom Styles Devoluciones*/
.dev_text h5{
  color: #153e69;
}
.dev_text {
  text-align: center;
  margin-bottom: 30px;
}
.dev_text h3{
  font-size: 32px;
  position: relative;
  display: inline-block;
  color: #153e69;
}
.dev_text h3:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 50px;
  border-top: 2px solid #153e69;
  left: -65px;
  top: 20px;
}
.dev_text h3:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 50px;
  border-top: 2px solid #153e69;
  right: -65px;
  top: 20px;
}
.dev_text h5{
  font-size:20px;
}
.col-1 h1{
  font-size: 2.5rem;
  margin-bottom: .5rem!important;
  font-weight: 500!important;
  line-height: 1.2!important;
}
.step-text,.step-info{
  font-size:18px;
  font-family: 'Lato', sans-serif;
  color: #153e69;
  line-height: 1.5;
}
.step-info, .step-small-text{
  margin-bottom: 1rem;
  font-family: 'Lato', sans-serif;
  line-height: 1.5;
  color: #153e69;
}
.step-small-text{
  font-size:17px;

  font-weight: 300;


}
.input-dev {
  border-radius: 5px!important;
  border: 1px solid #0a396d;
  max-width: 88%;
  display: inline-block;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin-left: 15px;
}
.input-pnr {
  border-radius: 5px!important;
  border: 1px solid #0a396d;
  //max-width: 44%;
  display: inline-block;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  //margin-left: 15px;
}
.input-email {
  border-radius: 5px!important;
  border: 1px solid #0a396d;
  //max-width: 44%;
  display: inline-block;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  //margin-left: 15px;

}



.primary-btn {
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid #b92234;
  border-radius: 10px;
  background: #b92234;
  line-height: 22px;
  padding: 12px 50px;
  position: relative;
  height: auto;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
}

.secondary-btn {
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid #b92234;
  border-radius: 10px;
  background: #b92234;
  line-height: 10px;
  padding: 12px 50px;
  position: relative;
  height: auto;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  float:right;
  top: -12px;
}


.disabled-btn {
  cursor: not-allowed;
  box-sizing: border-box;
  border: 2px solid rgb(173, 159, 159);
  border-radius: 10px;
  background: rgb(173, 159, 159);
  line-height: 22px;
  padding: 12px 50px;
  position: relative;
  height: auto;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
}


.dev-results {
  background-color: #ffffff;
  box-shadow: 0.2px 0.2px 10px rgba(0,0,0,0.25);
  border-radius: 10px;
  padding-left: 5em;
  padding-right: 5em;
}
.dev-results h4{
  font-size: 22px;
}
.icon-form {
  height: 40px;
}

.passenger {
  border-radius: 10px;
  border: 1px solid #0A355E;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 15px;
  position: relative;
}
.passenger div.row-passenger{
  border-bottom: 1px solid #ddd;
  margin-left: -15px;
  margin-right: -15px;
}
.passenger div.row-passenger:nth-of-type(1){
  // border-top:0;
}
.passenger div.row-total {
 // border-top: 1px solid #ddd;
}
.passenger h5{
  font-size: 20px;
}
.passenger p, .passenger label, .total p{
  font-size: 18px;
  margin-bottom: 0!important;
  color: #153e69
}
.results-small-text{
  font-size: 17px;
  color: #153e69;
  line-height: 1.5;
}
span.arrow {
  color: #b92234;
  font-weight: 900;
  font-size: xx-large;
}

.dev-amount-text {
  text-transform: uppercase;
  text-align: right;
  color: #153e69;
}
div.total {
  margin-right: 0;
  margin-bottom: 10px;
}
.passenger  div.dev-amount, .total div.dev-amount{
  border-radius: 5px;
  border: 1px solid #0A355E;
  padding: 0 5px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: right;
  line-height: 1.5;
}
.overlay {
  border-radius: 10px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  /*dim the background*/
  top:0;
  left:0;
  width:100%;
  height:100%;
  color:black;
  text-align: center;
  z-index: 1;
}
.input-card {
  border-radius: 5px;
  border: 1px solid #0A355E;
  margin-bottom: 10px;
  font-size: 16px;
}

.input-card::placeholder {
  color: #0A355E;
  opacity: 1;
}
.bank-account .custom-checkbox label{
  font-size: 17px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #fff;
}
.form-group.custom-control {
  padding-left: 1.5rem!important;
}
.custom-control-label{
  color: #153e69;
}
.custom-control-label::before, .form-check-label::before {
  border: 1px solid #0A355E !important;
}
.custom-control-input:checked~.custom-control-label::before
,  .form-check-input:checked~.form-check-label::before{
  color: #fff!important;
  background-color: #fff!important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image:url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23b92234' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  //background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23b92234' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-control-label::before {
  border: 1px solid #0A355E!important;
  background-color: #fff!important;
  outline: none !important;
  box-shadow: none !important;
}
.form-group {
  margin: 0!important;
  padding: 0!important;
}
.box-bank-account {
  margin: 25px 0;
  border: 1px solid #0A355E;
  border-radius: 10px;
  padding: 30px 0;
}
.form-group .custom-control-label, .form-group .form-check-label {
  line-height: 1.5;
  font-size: 17px;
}
.refundTotal{
  color: #153e69;
}
.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #485384;
  text-align: center;
  background-color: #CEE6ED;
  border-radius: .25rem;
}

/* .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #CEE6ED;
} */

/* Google reCaptcha */
.form-captcha-container {
  display: -webkit-inline-box;
}

.g-recaptcha {
  margin: 0 20px 0 0; }

@media (min-width: 75rem){
  .container {
    max-width: 1140px!important;
    padding-left: 15px!important;
    padding-right: 15px!important;
  }
}

@media (max-width: 1200px){
  .dev-results{
    padding-left: 3em;
    padding-right: 3em;
  }
  .passenger h5 {
    font-size: 18px;
  }
  .passenger p, .passenger label, .passenger div.dev-amount, .total div.dev-amount-text {
    font-size: 15px!important;
  }

  .results-small-text, .bank-account .custom-checkbox label {
      font-size: 15px;
  }
}
@media (max-width: 992px) {
  .dev-results h4{
      font-size: 18px;
  }
  .dev-results{
      padding-left: 1.5em;
      padding-right: 1.5em;
  }
}
@media (max-width: 768px){
  .dev_text h5 {
      font-size: 16px;
  }
  .dev_text h3 {
      font-size: 24px;
  }
  .step-text, .step-info {
      font-size: 14px;
  }
  .step-small-text {
      font-size: 14px;
  }
  .dev_text h3:before, .dev_text h3:after{
      display:none;
  }
  .passenger p, .passenger label, .passenger div.dev-amount, .dev-amount-text {
      font-size: 13px !important;
  }
  .results-small-text, .bank-account .custom-checkbox label {
      font-size: 13px;
  }
  .input-card{
      font-size: 14px;
  }
}

@media(max-width: 575px){
  .dev-results h4 {
      font-size: 16px;
  }
  .passenger h5 {
      font-size: 16px;
  }
  .passenger p, .passenger label, .passenger div.dev-amount, .dev-amount-text {
      font-size: 13px!important;
  }
}
@media (max-width: 380px) {
  app-chile-form col-12 {
    padding-left: 0;
    padding-right: 0;
  }
}
