.modal {
  display: none;
}
.modal {
  display: block;
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s, z-index 0s 0.2s;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.modal > * {
  display: inline-block;
  white-space: normal;
  vertical-align: middle;
  text-align: left;
}

.modal:before {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.modal-visible .modal {
  z-index: 99;
  opacity: 1;
  transition: opacity 0.2s;
}

.modal-inner {
  position: relative;
  overflow: hidden;
  padding: 40px;
  padding-top: 20px;
  min-width: 750px;
  max-width: 90%;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  z-index: -1;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, transform 0.2s, z-index 0s 0.2s;
}
.modal-visible .modal-inner {
  z-index: 100;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.2s, transform 0.2s;
}

[data-modal-close] {
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  color: #0a396d;
  font-weight: 700;
}
.modal-content {
  box-shadow: 0px 0px 0px white;
  border: 0px;
}
.modal-content .modal-title {
  margin-bottom: 30px;
  margin: 10px 0;
  margin-top: 0px;
  color: #0a396d;
  text-align: center;
}



.jetsmart-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 1000
}

.jetsmart-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 500px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    z-index: 1001;
    width: 30%;
}

.jetsmart-modal-content.argentina-modal {
    width: 1080px;
    max-width: 95%
}

.jetsmart-modal-content>button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border: none;
    background: #000;
    color: #fff;
    font-size: 36px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    //align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    font-weight: 400;
    z-index: 1;
    letter-spacing: unset;
    line-height: 1
}
.modal-header .close {
    color: #000;
    float: right;
    font-size: 24px;
    font-weight: bold;
}
.modal-header h3 {
    font-size: 1.75rem;
    margin-bottom: .5rem !important;
}
.icon-modal {
    height: 40px;
    margin-right: 10px;
}
.jetsmart-modal-content>button:hover {
    border: none;
    color: #163a70;
    text-decoration: none
}

.jetsmart-modal-content .modal-header {
    display: flex;
    //align-items: center;
    position: relative;
    padding: 10px 20px;
    border-radius: 3px;
    background: #00ACC8;;
    color: #163a70;
    font-weight: 900;
    font-size: 22px;
    justify-content: flex-start;
    user-select: none;
    text-align: left;
    overflow: hidden;    
}

.jetsmart-modal-content .modal-header i {
    margin-right: 20px;
    font-size: 36px;
    color: #59c3d9
}

.jetsmart-modal-content .modal-header button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    color: #59c3d9;
    font-size: 36px;
    cursor: pointer
}

.jetsmart-modal-content .modal-header button:hover {
    border: none;
    color: #163a70;
    text-decoration: none
}

.jetsmart-modal-content .modal-header button.seat-map {
    top: 0;
    right: 0;
    transform: translate(-50%, -50%)
}

.jetsmart-modal-content .modal-header.text-center {
    text-align: center;
    justify-content: center
}

.jetsmart-modal-content .modal-header .ribbon-text {
    color: #fff;
    background: #9c3434;
    display: block;
    padding: 2px 45px;
    position: absolute;
    bottom: 27px;
    left: -42px;
    transform: rotate(-25deg);
    font-size: 14px
}

.jetsmart-modal-content .modal-header .header-with-subtitle {
    display: inline-flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end
}

.jetsmart-modal-content .modal-header .header-with-subtitle span {
    display: block;
    font-weight: 600
}

.jetsmart-modal-content .modal-header .header-with-subtitle span:last-child {
    font-size: 14px
}

.jetsmart-modal-content .modal-body {
    padding: 20px
}
.jetsmart-modal-content .modal-body .static-modal-text {
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 800;
    line-height: 1.5;
    font-size: 16px;
    border:none;
    color: #153e69;
}
.jetsmart-modal-content .modal-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0px
}
.jetsmart-modal-content .modal-body .modal-button-container .btn-modal {
    font-size: .75rem;  
    letter-spacing: .05rem;
    padding: 9px 20px;
    text-transform: uppercase;
}

.jetsmart-modal-content .modal-button-container.modification-buttons-container {
    justify-content: center
}

.jetsmart-modal-content .modal-button-container a {
    cursor: pointer;
    margin: 0 10px 0 0
}

.jetsmart-modal-content .modal-button-container a:last-child {
    margin-right: 0
}

.jetsmart-modal-content .modal-button-container a.modal-btn {
    display: block;
    box-sizing: border-box;
    margin: 5px;
    padding: 1.25rem 2.25rem;
    border: 2px solid #7287a0;
    border-radius: 5px;
    background: #7287a0;
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem
}

.jetsmart-modal-content .modal-button-container a.modal-btn:hover {
    background: #163a70;
    border-color: #163a70;
    color: #fff
}

.jetsmart-modal-content .modal-button-container.button-center {
    justify-content: center
}

.jetsmart-flight-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 960px;
    max-width: 90%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #fff;
    z-index: 1001
}

.jetsmart-flight-modal-content.with-sidebar {
    padding-left: 290px;
    min-height: 570px
}

.jetsmart-flight-modal-content.login-modal {
    min-height: 480px
}

.jetsmart-flight-modal-content.taller {
    min-height: 720px
}

.jetsmart-flight-modal-content .mdl-textfield {
    padding: 20px 0 0
}

.jetsmart-flight-modal-content .js-input.js-select {
    font-size: 20px;
    padding-left: 15px;
    padding-top: 8px;
    color: #163a70;
    -webkit-appearance: none
}

.jetsmart-flight-modal-content .js-input.js-select option {
    font-size: 16px
}

.jetsmart-flight-modal-content .js-select-arrow:after {
    content: "\25BC";
    display: block;
    position: absolute;
    top: 39px;
    right: 15px;
    font-size: 12px;
    color: #ccc
}

.jetsmart-flight-modal-content .cb-title {
    line-height: 1.4
}

.jetsmart-flight-modal-content .cb-title a {
    color: #80bfd4
}

.jetsmart-flight-modal-content .cb-title a:hover {
    color: #163a70
}

.jetsmart-flight-modal-content .baggages-chb .chkbox-btn-wrapper,
.jetsmart-flight-modal-content .baggages-chb .chkbox-btn-wrapper .mdl-checkbox,
.jetsmart-flight-modal-content .baggages-chb .chkbox-btn-wrapper .mdl-checkbox.is-checked,
.jetsmart-flight-modal-content .baggages-chb .chkbox-btn-wrapper .mdl-checkbox.is-upgraded {
    background: transparent
}

.jetsmart-flight-modal-content .flight-modal-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 290px;
    background: #163a70 url(/V2FE/dist/images/dc-logo_64ebcabc2a988308c51b04bf16d3c7d8.png) no-repeat 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

.jetsmart-flight-modal-content .modal-header {
    font-size: 20px;
    color: #163a70;
    margin: 40px 20px 40px 310px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600
}

.jetsmart-flight-modal-content .modal-header.with-subtitle {
    text-align: left
}

.jetsmart-flight-modal-content .modal-header.with-subtitle .subtitle {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    text-transform: none
}

.jetsmart-flight-modal-content .modal-body {
    margin-left: 290px
}

.jetsmart-flight-modal-content .modal-body.bordered {
    margin: 20px 20px 20px 310px;
    border: 1px solid #ccc;
    border-radius: 10px
}

.jetsmart-flight-modal-content .modal-body-inner-box {
    margin: 20px;
    padding: 0 20px;
    background: #f7f7f7;
    border-radius: 10px
}

.jetsmart-flight-modal-content .modal-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1px 0
}

.jetsmart-flight-modal-content .modal-button-container.padded {
    margin: 20px
}

.jetsmart-flight-modal-content .modal-button-container.buttons-right {
    justify-content: flex-end
}

.jetsmart-flight-modal-content .modal-button-container.buttons-right button {
    margin-left: 10px
}

.jetsmart-flight-modal-content .modal-button-container.buttons-center {
    justify-content: center
}

.jetsmart-flight-modal-content .modal-button-container.buttons-center button {
    width: 170px
}

.jetsmart-flight-modal-content .modal-button-container.buttons-center button:first-child {
    margin-right: 10px
}

.jetsmart-flight-modal-content .modal-button-container.upgrade-buttons button {
    width: 200px
}

.jetsmart-flight-modal-content .secondary-btn {
    padding: 14px;
    color: #163a70;
    border-color: #163a70
}

.jetsmart-flight-modal-content .secondary-btn:hover {
    background: #163a70;
    color: #fff;
    border-color: #163a70
}

.argentina-prices-table td {
    padding: 10px;
    text-align: center;
    vertical-align: top;
    border: 1px solid #e2e2e2;
    line-height: 1.5;
    width: 14.2857%
}

.argentina-prices-table tr:first-child td {
    background: #163a70;
    color: #fff
}

@media (max-width:47.9375rem) {
    .jetsmart-modal-content {
        left: 5%;
        width: 90%;
        max-height: 90%;
        transform: translateY(-50%);
        overflow-y: auto
    }

    .jetsmart-modal-content.argentina-modal {
        left: 2%;
        width: 96%;
        max-height: 95%
    }

    .jetsmart-modal-content .modal-button-container {
        display: block
    }

    .jetsmart-modal-content .modal-button-container a {
        margin: 10px 0 0;
        width: 100%
    }

    .argentina-prices-table td {
        width: 50%;
        vertical-align: middle;
        text-align: left
    }

    .argentina-prices-table tr:first-child td,
    .argentina-prices-table tr td {
        background: unset;
        color: unset
    }

    .argentina-prices-table tr td:first-child {
        background: #163a70;
        color: #fff
    }
}

@media (min-width:64rem) and (max-width:74.9375rem) {

    .jetsmart-flight-modal-content.extension-modal,
    .jetsmart-flight-modal-content.membership-modal,
    .jetsmart-flight-modal-content.upgrade-modal {
        max-height: 570px
    }

    .jetsmart-flight-modal-content.login-modal {
        height: 480px
    }

    .jetsmart-flight-modal-content.register-modal {
        min-height: unset;
        height: 720px;
        max-height: 95%
    }
}

@media (max-width:74.9375rem) {
    .jetsmart-flight-modal-content {
        height: 90%;
        width: 50%;
    }

    .jetsmart-flight-modal-content .birthdate-container {
        margin-bottom: 0
    }

    .jetsmart-flight-modal-content .birthdate-container .input-inner-title {
        top: 20px
    }
}

@media (min-width:48rem) and (max-width:63.9375rem) {
    .jetsmart-modal-content {
        width: 50%;
    }
    .jetsmart-flight-modal-content.extension-modal {
        max-height: 730px
    }

    .jetsmart-flight-modal-content.upgrade-modal {
        max-height: 700px
    }

    .jetsmart-flight-modal-content.membership-modal {
        max-height: 740px
    }

    .jetsmart-flight-modal-content.login-modal {
        height: 650px
    }
}

@media (max-width:63.9375rem) {
    .jetsmart-flight-modal-content {
        top: 50%;
        left: 50%;
        min-width: 0;
        max-width: 690px;
        width: 95%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        background: #fff;
        z-index: 1001;
        height: 90%
    }

    .jetsmart-flight-modal-content.with-sidebar {
        padding-left: 0;
        padding-top: 170px;
        min-height: 360px
    }

    .jetsmart-flight-modal-content .modal-body,
    .jetsmart-flight-modal-content .modal-body.bordered,
    .jetsmart-flight-modal-content .modal-header {
        margin-left: 20px
    }

    .jetsmart-flight-modal-content .flight-modal-sidebar {
        bottom: auto;
        width: auto;
        right: 0;
        height: 170px;
        background: #163a70 url(/V2FE/dist/images/dc-logo_64ebcabc2a988308c51b04bf16d3c7d8.png) no-repeat 50%;
        background-size: auto 140px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0
    }

    .jetsmart-flight-modal-content .modal-button-container.buttons-right button {
        padding: 15px 30px;
        margin: 0
    }

    .jetsmart-flight-modal-content .primary-btn,
    .jetsmart-flight-modal-content .secondary-btn {
        display: inline-block;
        margin-right: unset
    }
}
// @media (min-width: )
@media (max-width:47.9375rem) {
    .jetsmart-flight-modal-content {
        height: 95%
    }

    .jetsmart-flight-modal-content.with-sidebar {
        padding-top: 110px
    }

    .jetsmart-flight-modal-content.extension-modal .modal-button-container,
    .jetsmart-flight-modal-content.upgrade-modal .modal-button-container {
        display: block
    }

    .jetsmart-flight-modal-content.extension-modal .modal-button-container .primary-btn,
    .jetsmart-flight-modal-content.upgrade-modal .modal-button-container .primary-btn {
        margin-left: unset
    }

    .jetsmart-flight-modal-content.extension-modal .modal-button-container button,
    .jetsmart-flight-modal-content.upgrade-modal .modal-button-container button {
        display: block;
        width: 100%
    }

    .jetsmart-flight-modal-content.extension-modal .modal-button-container button:first-child,
    .jetsmart-flight-modal-content.upgrade-modal .modal-button-container button:first-child {
        margin-bottom: 20px
    }

    .jetsmart-flight-modal-content.login-modal {
        height: 650px;
        max-height: 95%
    }

    .jetsmart-flight-modal-content .modal-header {
        margin: 20px;
        line-height: 1.4
    }

    .jetsmart-flight-modal-content .modal-button-container.buttons-right {
        justify-content: center
    }

    .jetsmart-flight-modal-content .flight-modal-sidebar {
        height: 110px;
        background-size: auto 90px
    }
}
.jetsmart-modal .btn, 
.jetsmart-modal button {    
  padding: 12px 50px;
  line-height: 22px;
  border-radius: 8px;    
  margin: 10px 0px !important;
  margin-bottom: 0!important;
  font-size: inherit;
  text-transform: none;
}