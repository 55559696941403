.main-wrap {
  background: #f7f7f7;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.static-content-body {
  margin: 0px 0;
  // background: #f7f7f7;
}
.static-content-body .static-content-sub.text-justified,
.static-content-body .static-content-title.text-justified,
.static-content-body .static-content-sub-small.text-justified {
    text-align: justify;
}
.static-content-body .static-content-sub,
.static-content-body .static-content-title,
.static-content-body .static-content-sub-small {
  color: #0a396d;
}
.static-content-body .static-content-title {
  text-align: center;
  color: #0a396d;
  font-size: 28px;
}
.static-content-body .static-content-sub {
  margin: 30px;
  font-size: 22px;
  font-weight: 300;
}
.static-content-body .static-content-sub-small {
  margin: 30px;
  font-size: 18px;
  font-weight: 300;
}
.refunds label[for=accept] {
  height: auto;
}
.refunds label[for=accept] {
  color: $secondary;
}
.refunds .btn {
    margin: 25px 0;
}

.btn {
  font-size: .75rem;
  letter-spacing: .05rem;
  padding: 9px 20px;
  text-transform: uppercase;
}

.btn-primary:not(:disabled):focus,
.btn-primary:not(:disabled):hover {
    color: $primary;
    background: #fff ;
    border: 1px solid #0a396d
}

.btn-secondary:not(:disabled):focus,
.btn-secondary:not(:disabled):hover {
    color: $secondary ;
    background: #fff ;
    border: 1px solid $secondary;
}
.maintitle {
  color: $blue-1;
  font-weight: 600;
}
