@font-face {
  font-family: 'jetsmart-icons';
  src: url(../fonts/jetsmart-icons_562ab689657a9df9eea29d841b8c5ef9.eot);
  src: url(../fonts/jetsmart-icons_562ab689657a9df9eea29d841b8c5ef9.eot?#iefix) format("embedded-opentype"), url(../fonts/jetsmart-icons_674dca0fa8d6fd86deabe32481c66784.woff2) format("woff2"), url(../fonts/jetsmart-icons_674dca0fa8d6fd86deabe32481c66784.woff) format("woff"), url(../fonts/jetsmart-icons_487a4f66a932f2ec068c4480dbf9636e.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}
@font-face {
    font-family: 'font-awesome';
    src: url(../fonts/font-awesome-brands.otf) format('opentype');
}
@font-face {
    font-family: 'font-awesome-free';
    src: url(../fonts/font-awesome-free.otf) format('opentype');
}
.fab {
    font-family: font-awesome;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.fas {
    font-family: font-awesome-free;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.jt-icon {
    font-family: jetsmart-icons;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    // speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.jt-auto:before {
    content: "\E95B"
}

.jt-flight-22:before {
    content: "\E95C"
}

.jt-sleep2:before {
    content: "\E95D"
}

.jt-arrow-down2:before {
    content: "\E95E"
}

.jt-arrow-left2:before {
    content: "\E95F"
}

.jt-arrow-right2:before {
    content: "\E9B4" //"\E960"
}

.jt-arrow-up2:before {
    content: "\E961"
}

.jt-blind2:before {
    content: "\E962"
}

.jt-bookings2:before {
    content: "\E963"
}

.jt-calendar2:before {
    content: "\E964"
}

.jt-call2:before {
    content: "\E965"
}

.jt-check-in2:before {
    content: "\E966"
}

.jt-circle-baggage2:before {
    content: "\E967"
}

.jt-circle-call2:before {
    content: "\E968"
}

.jt-circle-check-in2:before {
    content: "\E969"
}

.jt-circle-duration2:before {
    content: "\E96A"
}

.jt-circle-no2:before {
    content: "\E96B"
}

.jt-circle-ok2:before {
    content: "\E96C"
}

.jt-circle-payment2:before {
    content: "\E96D"
}

.jt-circle-plane2:before {
    content: "\E96E"
}

.jt-circle-question2:before {
    content: "\E96F"
}

.jt-circle-refresh2:before {
    content: "\E970"
}

.jt-circle-seat2:before {
    content: "\E971"
}

.jt-circle-small-seat2:before {
    content: "\E972"
}

.jt-circle-wheelchair2:before {
    content: "\E973"
}

.jt-close2:before {
    content: "\E974"
}

.jt-cocktail2:before {
    content: "\E975"
}

.jt-coffee2:before {
    content: "\E976"
}

.jt-credit-card2:before {
    content: "\E977"
}

.jt-deaf2:before {
    content: "\E978"
}

.jt-details2:before {
    content: "\E979"
}

.jt-document2:before {
    content: "\E97A"
}

.jt-edit2:before {
    content: "\E97B"
}

.jt-edit-22:before {
    content: "\E97C"
}

.jt-facebook-logo2:before {
    content: "\E97D"
}

.jt-faq-baggage2:before {
    content: "\E97E"
}

.jt-faq-check-in2:before {
    content: "\E97F"
}

.jt-faq-credit-card2:before {
    content: "\E980"
}

.jt-faq-plane2:before {
    content: "\E981"
}

.jt-faq-question2:before {
    content: "\E982"
}

.jt-faq-refresh2:before {
    content: "\E983"
}

.jt-faq-seat2:before {
    content: "\E984"
}

.jt-faq-time2:before {
    content: "\E985"
}

.jt-faq-wheelchair2:before {
    content: "\E986"
}

.jt-flight2:before {
    content: "\E987"
}

.jt-flight-in2:before {
    content: "\E988"
}

.jt-flight-out2:before {
    content: "\E989"
}

.jt-flight-ticket2:before {
    content: "\E98A"
}

.jt-food2:before {
    content: "\E98B"
}

.jt-gift2:before {
    content: "\E98C"
}

.jt-globe2:before {
    content: "\E98D"
}

.jt-hand-baggage2:before {
    content: "\E98E"
}

.jt-hotel2:before {
    content: "\E98F"
}

.jt-info2:before {
    content: "\E990"
}

.jt-instagram-logo2:before {
    content: "\E991"
}

.jt-insurance2:before {
    content: "\E992"
}

.jt-large-baggage2:before {
    content: "\E993"
}

.jt-large-seat2:before {
    content: "\E994"
}

.jt-letter2:before {
    content: "\E995"
}

.jt-lock2:before {
    content: "\E996"
}

.jt-man-person2:before {
    content: "\E997"
}

.jt-minus-sign2:before {
    content: "\E998"
}

.jt-person2:before {
    content: "\E999"
}

.jt-pin2:before {
    content: "\E99A"
}

.jt-plus-sign2:before {
    content: "\E99B"
}

.jt-print2:before {
    content: "\E99C"
}

.jt-register2:before {
    content: "\E99D"
}

.jt-search2:before {
    content: "\E99E"
}

.jt-seat2:before {
    content: "\E99F"
}

.jt-seat-large2:before {
    content: "\E9A0"
}

.jt-security2:before {
    content: "\E9A1"
}

.jt-sign-in2:before {
    content: "\E9A2"
}

.jt-sign-out2:before {
    content: "\E9A3"
}

.jt-size-large-baggage2:before {
    content: "\E9A4"
}

.jt-size-large-cabin-baggage2:before {
    content: "\E9A5"
}

.jt-size-small-baggage2:before {
    content: "\E9A6"
}

.jt-small-baggage2:before {
    content: "\E9A7"
}

.jt-sport2:before {
    content: "\E9A8"
}

.jt-time2:before {
    content: "\E9A9"
}

.jt-toilette2:before {
    content: "\E9AA"
}

.jt-traveller2:before {
    content: "\E9AB"
}

.jt-twitter-logo2:before {
    content: "\E9AC"
}

.jt-view2:before {
    content: "\E9AD"
}

.jt-voucher2:before {
    content: "\E9AE"
}

.jt-warning2:before {
    content: "\E9AF"
}

.jt-wheelchair2:before {
    content: "\E9B0"
}

.jt-women-person2:before {
    content: "\E9B1"
}

.jt-youtube-logo2:before {
    content: "\E9B2"
}

.jt-arrow-down:before {
    content: "\E904"
}

.jt-arrow-left:before {
    content: "\E905"
}

.jt-arrow-right:before {
    content: "\E906"
}

.jt-arrow-up:before {
    content: "\E907"
}

.jt-blind:before {
    content: "\E908"
}

.jt-bookings:before {
    content: "\E909"
}

.jt-calendar:before {
    content: "\E90A"
}

.jt-call:before {
    content: "\E90B"
}

.jt-check-in:before {
    content: "\E90C"
}

.jt-circle-baggage:before {
    content: "\E90D"
}

.jt-circle-call:before {
    content: "\E90E"
}

.jt-circle-check-in:before {
    content: "\E90F"
}

.jt-circle-duration:before {
    content: "\E910"
}

.jt-circle-no:before {
    content: "\E911"
}

.jt-circle-ok:before {
    content: "\E912"
}

.jt-circle-payment:before {
    content: "\E913"
}

.jt-circle-plane:before {
    content: "\E914"
}

.jt-circle-question:before {
    content: "\E915"
}

.jt-circle-refresh:before {
    content: "\E916"
}

.jt-circle-seat:before {
    content: "\E917"
}

.jt-circle-small-seat:before {
    content: "\E918"
}

.jt-circle-wheelchair:before {
    content: "\E919"
}

.jt-close:before {
    content: "\E91A"
}

.jt-cocktail:before {
    content: "\E91B"
}

.jt-coffee:before {
    content: "\E91C"
}

.jt-credit-card:before {
    content: "\E91D"
}

.jt-deaf:before {
    content: "\E91E"
}

.jt-details:before {
    content: "\E91F"
}

.jt-document:before {
    content: "\E920"
}

.jt-edit:before {
    content: "\E921"
}

.jt-edit-2:before {
    content: "\E922"
}

.jt-facebook-logo:before {
    content: "\E923"
}

.jt-faq-baggage:before {
    content: "\E924"
}

.jt-faq-check-in:before {
    content: "\E925"
}

.jt-faq-credit-card:before {
    content: "\E926"
}

.jt-faq-plane:before {
    content: "\E927"
}

.jt-faq-question:before {
    content: "\E928"
}

.jt-faq-refresh:before {
    content: "\E929"
}

.jt-faq-seat:before {
    content: "\E92A"
}

.jt-faq-time:before {
    content: "\E92B"
}

.jt-faq-wheelchair:before {
    content: "\E92C"
}

.jt-flight:before {
    content: "\E92D"
}

.jt-flight-2:before {
    content: "\E92E"
}

.jt-flight-in:before {
    content: "\E92F"
}

.jt-flight-out:before {
    content: "\E930"
}

.jt-flight-ticket:before {
    content: "\E931"
}

.jt-food:before {
    content: "\E932"
}

.jt-gift:before {
    content: "\E933"
}

.jt-globe:before {
    content: "\E934"
}

.jt-hand-baggage:before {
    content: "\E935"
}

.jt-hotel:before {
    content: "\E936"
}

.jt-info:before {
    content: "\E937"
}

.jt-instagram-logo:before {
    content: "\E938"
}

.jt-insurance:before {
    content: "\E939"
}

.jt-large-baggage:before {
    content: "\E93A"
}

.jt-large-seat:before {
    content: "\E93B"
}

.jt-letter:before {
    content: "\E93C"
}

.jt-lock:before {
    content: "\E93D"
}

.jt-man-person:before {
    content: "\E93E"
}

.jt-minus-sign:before {
    content: "\E93F"
}

.jt-person:before {
    content: "\E940"
}

.jt-pin:before {
    content: "\E941"
}

.jt-plus-sign:before {
    content: "\E942"
}

.jt-print:before {
    content: "\E943"
}

.jt-register:before {
    content: "\E944"
}

.jt-search:before {
    content: "\E945"
}

.jt-seat:before {
    content: "\E946"
}

.jt-seat-large:before {
    content: "\E947"
}

.jt-security:before {
    content: "\E948"
}

.jt-sign-in:before {
    content: "\E949"
}

.jt-sign-out:before {
    content: "\E94A"
}

.jt-size-large-baggage:before {
    content: "\E94B"
}

.jt-size-large-cabin-baggage:before {
    content: "\E94C"
}

.jt-size-small-baggage:before {
    content: "\E94D"
}

.jt-sleep:before {
    content: "\E94E"
}

.jt-small-baggage:before {
    content: "\E94F"
}

.jt-sport:before {
    content: "\E950"
}

.jt-time:before {
    content: "\E951"
}

.jt-toilette:before {
    content: "\E952"
}

.jt-traveller:before {
    content: "\E953"
}

.jt-twitter-logo:before {
    content: "\E954"
}

.jt-view:before {
    content: "\E955"
}

.jt-voucher:before {
    content: "\E956"
}

.jt-warning:before {
    content: "\E957"
}

.jt-wheelchair:before {
    content: "\E958"
}

.jt-women-person:before {
    content: "\E959"
}

.jt-youtube-logo:before {
    content: "\E95A"
}

.jt-full-board .path1:before {
    content: "\E900";
    color: #000
}

.jt-full-board .path2:before {
    content: "\E901";
    margin-left: -1.0556640625em;
    color: #000
}

.jt-full-board .path3:before {
    content: "\E902";
    margin-left: -1.0556640625em;
    color: #fff
}

.jt-full-board .path4:before {
    content: "\E903";
    margin-left: -1.0556640625em;
    color: #000
}
