footer{
	margin-top:30px;
	background-color:#FFFFFF;	
	padding-top:30px;
	font-family: 'Lato', sans-serif;
  color:#204071;
  line-height: 1.5;
}
footer #copyright .row {
  line-height: 0!important;
}
footer .d-inline-block .d-block a i {
  //line-height: 1.5!important;
}
footer h5 {
  font-size: 1.25rem!important;
  margin-top: 0;
  margin-bottom: .5rem;
}
footer ul.list-unstyled {
  margin-bottom: 1rem;
}
footer * {
	font-family: 'Lato', sans-serif;
}
footer a .hover-border-bottom-left{
	display:inline-block;
}
footer a .hover-border-bottom-left:after {
  display:block;
  content: '';
  border-bottom: solid 1px #E9B22A;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin:  0% 50%;
  margin-top: -2px;
}
footer a:hover .hover-border-bottom-left:after { transform: scaleX(1);  }
.cursor-pointer{
	cursor: pointer;
}
footer hr{
	border-color:#114171;
}
footer #footer_cols h5{
	color:#114171;
	font-weight:800;
}
footer #footer_cols h5 i{
	font-size: 1.5rem;
}
footer #footer_cols h5 i.fa-plus-square:before{
	content:"\f146";
}
footer #footer_cols h5.collapsed i.fa-plus-square:before{
	content: "\f0fe";
}
footer #footer_cols a{
	color:#666968;
}
footer #footer_cols a:hover{
	text-decoration: none;
}
footer #info .contact-footer h4{
	color:#114171;
	font-weight:800;
}
footer #info .contact-footer p a{
	color:#114171;
}
footer #info .contact-footer p a:hover{
	text-decoration: none;
}
footer #info .payments-footer p{
	font-size: 18px;
}
footer #info .payments-footer > div > div img:first-child{
	margin-bottom: 10px;
}
footer #info .payments-footer .webpay-redcompra{
	margin-bottom: 0px !important;
	max-height: 100px;
}
footer #info .payments-footer .diners-club{
	margin-bottom: 0px !important;
}
footer #info .payments-footer .safetypay{
	margin-bottom: 0px !important;
}
footer #info .payments-footer .visa, footer #info .payments-footer .mastercard, footer #info .payments-footer .american-express, footer #info .payments-footer .magna, footer #info .payments-footer .diners-club{
	max-height: 37px;
	border: 1px solid #dedede;
}
footer #info .rss-footer p{
	font-size: 18px;
}
footer #info .rss-footer a{
	color:#114171;
	transition: color 250ms ease-in-out;
}
footer #info .rss-footer a:hover{
	color:#903235;
}
footer #info .rss-footer i{
	font-size: 32px;
}

footer hr.red{
	border-color: #903235;
} 
.border-top-8px{
	border-top: 8px solid;
}
@media (max-width:1200px) and (min-width:991px){
	footer #footer_cols h5{
		font-size: 1.15rem;
	}
	footer #footer_cols a{
		font-size: 0.8rem;
	}
}
@media (max-width:992px) and (min-width:767px){
	footer #footer_cols h5{
		font-size: 1.05rem;
	}
	footer #footer_cols a{
		font-size: 0.64rem;
	}
}
@media (max-width:992px){
	footer #info .contact-footer h4{
		font-size: 1.2rem;
	}
	footer #info .contact-footer p a {
		font-size: 0.8rem;
	}
	footer #info .payments-footer p{
		font-size: 0.8rem;
	}
	footer #info .rss-footer p{
		font-size: 0.8rem;
	}
	footer #info .rss-footer i {
		font-size: 26px;
	}
	footer #copyright p{
		font-size: 0.85rem;
		text-align: left;
	}
}
@media (max-width:768px){
	footer #info .contact-footer h4{
		font-size: 1.2rem;
	}
	footer #info .contact-footer p{
		line-height: 1.1;
	}
	footer #info .contact-footer p a {
		font-size: 0.75rem;
	}
	footer #info .contact-footer p a .hover-border-bottom-left:after{
		margin-top:0;
	}
	footer #info .rss-footer p{
		font-size: 0.8rem;		
	}
	footer #info .rss-footer i {
		font-size: 24px;
		margin-right: 0.5rem!important;
	}
}
@media (max-width:575px){
	footer{
		padding-top: 30px;
	}
	footer #info .payments-footer p{
		font-size: 1rem;		
	}
	footer #info .rss-footer p{
		font-size: 1rem;		
	}
	footer #info .payments-footer .text-left{
		text-align: center !important;
	}
	footer #info .rss-footer.text-right{
		text-align: center !important;
	}
	footer .container.mt-5{
		margin-top:1rem !important;
	}
	footer #copyright .text-right, footer #copyright .text-left{
		text-align:center !important;
	}
	footer #copyright .logo-jetsmart-footer{
		margin-top:15px;
		max-width:180px;
	}
}