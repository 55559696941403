/* You can add global styles to this file, and also import other style files */
$material-icons-font-path: '~material-icons/iconfont/';
@import 'variables';
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/material-icons/iconfont/material-icons.scss';
//@import '../../node_modules/material-icons/css/material-icons.scss';
@import 'jetsmart-icons';
@import 'modal';
@import 'forms';
@import 'reset';
@import 'basic';
@import 'loading';
//@import 'list';
@import 'new_style';
@import 'text';
@import 'jetsmart_footer_corporate';
@import '~@ng-select/ng-select/themes/default.theme.css';
